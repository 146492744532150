import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { Router } from '@angular/router';
declare var $:any
declare var window:any
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  searchKey : any;
  filterBy : any;
  fromDate : any;
  toDate : any;
  submittedFormsList : any = [];
  accountDetails: any;
  currentDate: string;
  maxFromDate: string;
  maxToDate: string;
  minToDate: any;
  page: any=1;
  total:any=0;
  type: any='COMPLETE';
  selectedStatus: any;
  selectedFormId: any;
  selectedObj: any={};
  selectedIndex: any;
  questionSurveyArr :any=[]

  test="Please select the reasons for your withdrawal , Academic Reasons/Please explain further/Free Text | Dissatisfaction with Course content/Please explain further/Free Text | admin"
  selectedActionObj: any={};
  questionsArr: any=[];
  selectedErrorIndex: any;
  mainIndex: any;
  subIndex: any;
  actionList: any=[]
  appLogArr: any=[];
  constructor(private service:ServicesService,private router:Router) {
    

   }

  ngOnInit() {
      localStorage.removeItem('section1')
      localStorage.removeItem('section2')
      localStorage.removeItem('section3')
      localStorage.removeItem('section4')
      localStorage.removeItem('section5')
      localStorage.removeItem('section6')
      localStorage.removeItem('section7')
      localStorage.removeItem('section8')
      localStorage.removeItem('section9')
      localStorage.removeItem('section10')
      localStorage.removeItem('section11')
      localStorage.removeItem('section12')
      localStorage.removeItem('section13')
      localStorage.removeItem('section14')
    window.scrollTo(0, 0);
    this.accountDetails = JSON.parse(localStorage.getItem('myProfile'))
    // this.getSubmiitedForms('ASC_P')
    this.getSubmiitedForms(this.type)
    this.getActions()
  }

  getActions() {
    let url = 'application/get-role-action-status?page=0&pageSize=999'
    this.service.getApi(url,1).subscribe((res:any) => {
      if(res.body.status == 200) {
       this.actionList = res.body.data.data.content
      }
    })

  }

  onFromChangeDate(){
    this.minToDate = this.fromDate;
  }
  onToChangeDate(){
    this.maxFromDate = this.toDate;
  }
  dateValidation(){
    let date = new Date();
    let currentDay = date.getDate() >= 10 ? date.getDate(): '0'+ date.getDate();
    let currentMonth = (date.getMonth() + 1) >= 10 ? (date.getMonth() + 1): '0'+date.getMonth();
    let currentYear = date.getFullYear();
    this.maxFromDate = currentYear + '-' + currentMonth + '-' + currentDay;
    this.maxToDate = currentYear + '-' + currentMonth + '-' + currentDay;
  }


 
  getSubmiitedForms(type){
    this.type=type
    // let url = `course/filter-forms-details?page=0&formFillStatus=COMPLETE&representativeId=${this.accountDetails.representativeDetailsId}`
    let url = `application/search-and-filters-forms?currentStatus=${this.type}&userId=${this.accountDetails.userId}&page=${this.page-1}&pageSize=10&role=STUDENT`
   
    if(this.fromDate && this.toDate){
      url = url + `&fromDate=${this.convertIntoTimeStamp(this.fromDate)}&toDate=${this.convertIntoTimeStamp(this.toDate)}`
    }

    if(this.filterBy && this.searchKey){
      if(this.filterBy == 'search_by_form_id') {
        url = url + `&formId=${this.searchKey}`
      }else if(this.filterBy == 'submitted_by') {
        url = url + `&submitedBy=${this.searchKey}`
      }else if(this.filterBy == 'candidateName') {
        url = url + `&candidateName=${this.searchKey}`
      }
    }
    this.service.getApi(url,1).subscribe((res:any) => {
      this.service.hideSpinner()
      if(res.body.status == 205 || res.body.status == 200){
        this.submittedFormsList = res.body.data.list
        this.submittedFormsList.forEach(ele => {
          ele['parsedData']= JSON.parse(ele.formData)
        });
        this.total = res.body.data.totalCount
        console.log(this.submittedFormsList)
      }
    })
  }

  reset(){
    this.searchKey = undefined
    this.filterBy = undefined
    this.fromDate = undefined
    this.toDate = undefined
    this.getSubmiitedForms(this.type)
  }

  convertIntoTimeStamp(myDate) {
    myDate = myDate.split("-");
    var newDate = myDate[1] + "/" + myDate[2] + "/" + myDate[0];
    console.log(new Date(newDate).getTime());
    return (new Date(newDate).getTime())
  }

  viewHistory(id){
    this.router.navigateByUrl(`view-form/${id}`)
  }

  applicationProgress(id){
    this.router.navigateByUrl(`application-progress/${id}`)
  }

  onSelectTab(tabname,type) {
    if(tabname == 'submittedApplication') {
      this.getSubmiitedForms(type)
    }
  }

  onFilterSelect(event) {
    this.filterBy = event.target.value
    console.log(this.filterBy)
  }

  manageLog(log) {
    this.appLogArr = []
    console.log(log)
    if(log!=null && log.length >0) {
      // console.log(log)
      // this.appLogArr = JSON.parse(log)
      // '{"name":"shivam","job":"developer"}|{"name":"shivam1","job":"developer1"}'
     log.forEach(ele => {
        this.appLogArr.push(JSON.parse(ele.applicationLog))
      });
    }
    $('#appLog').modal('show')
  }


  // to update form status
  updateStatus(formId,obj,val,k,j) {
    this.mainIndex = k
    this.subIndex = j
    this.selectedFormId = formId;
    this.selectedObj = obj
    console.log(this.selectedObj)
    this.selectedStatus = val
    this.selectedIndex = this.selectedObj.statusList[0].studentAction.split(',').findIndex(x=>x.trim() == this.selectedStatus.trim())
    let action = this.selectedObj.statusList[0].studentAction.split(',')[this.selectedIndex]
    let code = this.selectedObj.statusList[0].studentAppStatusCode.split(',')[this.selectedIndex]
    this.selectedActionObj = this.actionList.find(x=>x.actionName.trim()==action.trim() && x.applicationStatusCode.trim()==code.trim())
    // this.selectedActionObj = (this.selectedObj.actionList.find(x=>x.actionName.trim()==action.trim() && x.applicationStatusCode.trim()==code.trim()))
    // this.selectedActionObj['detail'] = JSON.parse(this.selectedActionObj.details)
    console.log(this.selectedActionObj) 
    
    let questionsObj;
    if(this.selectedActionObj.detailType == 'Survey' || this.selectedActionObj.detailType == 'Additional Documents-Static' || this.selectedActionObj.detailType == 'Additional Documents-Dynamic'){
      if(this.selectedActionObj.detailType == 'Additional Documents-Dynamic') {
        console.log('called', this.selectedObj)
        console.log('called', JSON.parse(this.selectedObj.additionalDocuments))
        this.selectedActionObj.details = JSON.parse(this.selectedObj.additionalDocuments)
      }
        questionsObj = this.selectedActionObj.details.split(',')[2]
      console.log(questionsObj)
    this.questionsArr = []
    questionsObj.split(':').forEach(ele => {
      // console.log(ele)
      this.questionsArr.push(
        {
          question: ele.split('|')[0].trim(),
          type: ele.split('|')[1].trim(),
          placement: ele.split('|')[2].trim(),
          mandatory: ele.split('|')[3].trim(),
          optionsArr: ele.split('|')[1].trim() == 'dropdown' ? ele.split('|')[2].trim().split('/') : []
        }
      )
    });
    console.log(this.questionsArr)
      $('#uploadSurvey').modal({backdrop:'static',keyboard:false})
    }else if(this.selectedActionObj.detailType == 'None') {
      $('#uploadNone').modal({backdrop:'static',keyboard:false})
      }
    }

  onDropChange(index,val) {
    this.questionsArr[index]['value'] = val
  }

  // on update api call
  onUpdateStatusCall() {
    let obj = {sender:'user',receiver:'university',applicationStatus:this.selectedActionObj.applicationStatusName,current_time:new Date().getTime()}

    let repCodeArr = this.selectedObj.statusList[0].repAppStatusCode.split(',')
    let accountDetails = localStorage.getItem('myProfile') ? JSON.parse(localStorage.getItem('myProfile')) : {}
    // let url = `application/form-status-update?formId=${this.selectedFormId}&role=REPRESENTATIVE&status=${repCodeArr[this.selectedIndex]}&universityId=${this.selectedObj.universityId}&userId=${accountDetails.userId}&applicationLog=${appLogArr.join(',')}`
    let url = `application/form-status-update?userId=${accountDetails.userId}`
    let req= {
      'formId': this.selectedFormId,
      'role':'STUDENT',
      'status': repCodeArr[this.selectedIndex],
      'userId': this.accountDetails.userId,
      'universityId': this.selectedObj.universityId,
      "applicationLogDto": [
        {
          "applicationLog": JSON.stringify(obj),
          "formId": this.selectedFormId,
          // "fromRepresentative": "",
          // "fromStudent": "string",
          // "fromUniversity": "string",
          "universityId": this.selectedObj.universityId
        }
      ],
    }
    this.service.showSpinner()
    this.service.putApi(url,req).subscribe((res:any) => {
      this.service.hideSpinner()
      if(res.body.status == 200){
        $('#uploadSurvey').modal('hide')
        $('#uploadNone').modal('hide')
        this.selectedFormId = null;
        this.selectedObj = {}
        this.selectedStatus = null
        this.selectedIndex = null
        this.selectedErrorIndex = null
        this.getSubmiitedForms(this.type)
      }else {
        this.appLogArr.splice(this.appLogArr.length-1,1)
      }
    })
  }

close() {
  console.log('main-list-select'+this.mainIndex+this.subIndex)
  document.getElementById('main-list-select'+this.mainIndex + this.subIndex)['value']=""
  this.selectedFormId = null;
  this.selectedObj = {}
  this.selectedStatus = null
  this.selectedIndex = null
  this.selectedErrorIndex = null
  // let arr = [...this.submittedFormsList]
  // this.submittedFormsList = []
  // setTimeout(() => {
  //   this.submittedFormsList = [...arr]
  // }, 500);
  }

  onSurveySubmit() {
    this.selectedErrorIndex = null
    this.questionsArr.forEach((ele,index) => {
      console.log(index)
      ele['value'] = ele.value ? ele.value : document.getElementById(String(index))['value']
       if(ele.type == 'dropdown' && ele.value == 'yes') {
        ele['description'] = document.getElementById(index+'desc')['value']
      }
    });
    this.selectedErrorIndex = this.questionsArr.findIndex(x=>((x.value == '' && x.mandatory=='true') || (x.type=='dropdown' && x.value=='none') ))
    if(this.selectedErrorIndex != -1) {
      console.log(this.selectedErrorIndex)
      this.service.toastErr('Form Invalid')
      return;
    }
    let req = {
      fromEmail : this.accountDetails.email,
      toEmail: this.selectedActionObj.details.split(',')[2].trim() == 'admin' ? this.service.adminEmail :  this.selectedObj.universityEmail,
      message: JSON.stringify(this.questionsArr)
    }

    let url = 'application/send-mail'
    this.service.postApi(url,req,1).subscribe((res:any)=>{
      console.log(res)
      if(res.status == 200) {
        this.service.showSuccess(res.body.message)
        this.onUpdateStatusCall()
      }else {
        this.service.toastErr(res.body.message)
        this.close()
      }
    },(err)=>{
      this.service.toastErr("Can't send Email!")
        this.close()
    })
  }

  handleFileInput(event,index) {
    console.log(event)
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      this.uploadFile(index,file )
    }
  }

  removeFile(index) {
    document.getElementById(String(index))['value'] = null
  } 

  uploadFile(index,file) {
    var formData = new FormData()
    formData.append('file', file)
    this.service.showSpinner()
    this.service.postMethodMultipart('account/upload-file', formData).subscribe((res) => {
      this.service.hideSpinner()
      if(res.status == 200) {
        this.questionsArr[index]['value'] = res.data
        this.service.showSuccess('File Uploaded!')
      }else {
        document.getElementById(String(index))['value']=''
      }
    })
  }

  
}
