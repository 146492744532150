import { Component, OnInit } from '@angular/core';
import { ServicesService } from 'src/app/services.service';
import { FormControl } from '@angular/forms';
import { apiUrl } from 'src/environments/environment.prod';

declare var $: any;
@Component({
  selector: 'app-matching-universities',
  templateUrl: './matching-universities.component.html',
  styleUrls: ['./matching-universities.component.css']
})
export class MatchingUniversitiesComponent implements OnInit {

  countryList: any = []
  universityList: any = []
  myControl = new FormControl();
  filteredOptions:any=[]
  countryName: any = ''
  selectall: boolean = false
  selectedCountry: any;

  constructor(private service: ServicesService) { 
    if(localStorage.getItem('formDetails')) {
      this.service.formDetails = JSON.parse(localStorage.getItem('formDetails'))
    } 
  }

  ngOnInit() {
    if(this.service.formDetails.section1.courseCountry) {
      this.selectedCountry = this.service.formDetails.section1.courseCountry
    }
    window.scrollTo(0, 0);
    // let country = JSON.parse(localStorage.getItem('section1'))
    // this.section3Data = JSON.parse(localStorage.getItem('section3'))
    this.getUniversity();
    this.getCountry();
    // this.service.getCountryStates()
    //   .subscribe((data) => {
    //     this.countryList = data
    //     this.myControl.patchValue(country.countryName)
    //     this.getUniversity()
    //     this.filteredOptions = this.myControl.valueChanges
    //       .pipe(
    //         startWith(''),
    //         map(value => this._filter(value))
    //       );
    //   }, (error) => {
    //   });
  }

  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();
  //   return this.countryList.filter(option => option.country.toLowerCase().includes(filterValue));
  // }

  getCountry() {
    let url = apiUrl.getCountry + `?page=0&pageSize=999`;
    this.service.showSpinner();
    this.service.getAPI(url).subscribe(res => {
      this.service.hideSpinner();
      if (res.body.status == 200) {
        this.filteredOptions = res.body.data.allData.content;
        // this.filteredOptions = [...this.countryArr]
      }
    })
  }

  selectCountry(value) {
    this.selectedCountry = value
    // console.log("-->",this.myControl.value)
  }

  reset() {
    this.countryName = '';
  }


  getUniversity() {
    
    this.universityList = [];
    // let arr = []
    // arr = [...this.service.formDetails.section4.qualificationArr]
    let req = {}
    
    let url = apiUrl.course.matchLogic
    req['isEnglishProffTrue'] = this.service.formDetails.section5.professionalQualification == 'true'
    req['isMba']=this.service.formDetails.section1.subjectCode.toUpperCase().includes('MBA')
    req['qualificationType']= this.service.formDetails.section1.qualificationType
    req['workExperience']= Number(this.service.formDetails.section7.totalExperience)
    req['country']=this.service.formDetails.section1.courseCountry
    if(req['isEnglishProffTrue'] == true){
      req['englishProfTrueDto'] = {
        "englishProfessionalCode": this.service.formDetails.section5.englishQualificationName,
        'seoCode' : this.service.formDetails.section1.subjectCode,
        "listening": this.service.formDetails.section5.englishQualificationScore2,
        "overAllGrade": this.service.formDetails.section5.overallBandScore,
        "reading": this.service.formDetails.section5.englishQualificationScore1,
        "speaking":this.service.formDetails.section5.englishQualificationScore4,
        "writing":this.service.formDetails.section5.englishQualificationScore3
      }
    }else {
      req['englishProfFalseDto'] = {
        'seoCode' : this.service.formDetails.section1.subjectCode,
        "englishAcademicQualificationCode": this.service.formDetails.section5.typeOfEnglishQualification,
        "resultExpected":  this.service.formDetails.section5.overallResult,
        "resultType": this.service.formDetails.section5.resultType
      }
    }
    let arr = []
    if(this.service.formDetails.section4.qualificationArr.length > 0) {
      // for (let i = 0; i < this.service.formDetails.section4.qualificationArr.length ; i ++) {
        arr.push({
          "academicQualificationCode": this.service.formDetails.section4.qualificationArr[0].qualification,
          "country": this.service.formDetails.section4.qualificationArr[0].country,
          "gradeType":this.service.formDetails.section4.qualificationArr[0].resultGrade,
          "overallGrade": this.service.formDetails.section4.qualificationArr[0].overallGrade,
          "qualification": this.service.formDetails.section4.qualificationArr[0]['qualification']
        })
      // }
      req['academicQualificationRequestDto'] =arr[0]
    }else {
      req['academicQualificationRequestDto']= []
    }
      
    // if(this.service.formDetails.section1.subjectCode) {
    //   // let obj = this.filteredOptions.find(x=>x.value.trim() == this.selectedSubject.trim())
    //   url = url + `&seoCode=${this.service.formDetails.section1.subjectCode}`
    // }

    
   
    this.service.postApi(url,req,1).subscribe((res:any)=>{
      console.log(res)
      if(res.body.status == 200) {

        let matchUnivArr = res.body.data;
        matchUnivArr.forEach((ele,index) => {
          if(index == 0){
            this.universityList.push(ele)
          }else {
            let present = this.universityList.findIndex(x=>x.courseId == ele.courseId)
            if(present == -1){
              this.universityList.push(ele)
            }
          }
        });
        let arr = []
        arr = localStorage.getItem('selectedUniversities') ? JSON.parse(localStorage.getItem('selectedUniversities')): []
       
        this.universityList.map((ele)=>{
          ele.selected = false
        })
        arr.forEach(element => {
          let checkIndex = this.universityList.findIndex(x=>x.courseId == element.courseId)
          if(checkIndex == -1) {
            this.universityList.push(element)
          }else {
            this.universityList[checkIndex].selected = true
          }
        });
        if(this.universityList.length == 0) {
          this.service.toastErr('No course found!')
        }
      }else {
        this.service.toastErr(res.body.message)
      }
    },(err:any)=>{
      this.service.toastErr(err.error.error)
    })
  }


  selectAll(event) {
    this.universityList.forEach(element => {
      element.selected = event.srcElement.checked
    });
  }

  select(event, index) {
    this.universityList[index].selected = event.srcElement.checked
  }

  submit() {
    console.log("-->>", this.universityList)
    // this.universityList.forEach(element => {
    //   if (element.selected) {
    //     this.matchingDto.push({
    //       "universityEmail": element.primaryEmail,
    //       "universityId": element.universityDetailsId,
    //       "universityName": element.universityName,
    //       "imageUrl": element.imageUrl,
    //       "universityRefrence": element.universityCode
    //     })
    //   }

    // });

    // [routerLink]="['/dashboard']"    
    this.fillForm()
  }

  fillForm() {
    let req = {}
    let index = this.universityList.findIndex(x=>x.selected == true)
    if(index == -1) {
      this.service.toastErr('Please choose a course!')
      return;
    }else {

      req['candidateName'] = this.service.formDetails.section2.prefferedName + ' ' + this.service.formDetails.section2.surName;
      req['subjectCode'] = this.service.formDetails.section1.subjectCode;
      req['courseName'] = this.service.formDetails.section1.courseName;
      req['currentStatus'] = 'COMPLETE';
      req['formData'] = JSON.stringify(this.service.formDetails);
      req['representativeEmail'] = this.service.formDetails.representativeEmail;
      req['representativeName'] = this.service.formDetails.representativeName;
      req['submittedBy'] = this.service.formDetails.representativeName;
      req['formMatchingDto'] = []
      // to form university data
      this.universityList.forEach(element => {
        if(element.selected == true) {
          req['formMatchingDto'].push({
            "status": "ASC_P",
            "representativeEmail": this.service.formDetails.representativeEmail ?  this.service.formDetails.representativeEmail : '' ,
            "representativeName": this.service.formDetails.representativeName ?  this.service.formDetails.representativeName : '' ,
            // "studentEmail": this.service.formDetails.studentEmail ?  this.service.formDetails.studentEmail : '',
            // "studentName": this.service.formDetails.studentName ?  this.service.formDetails.studentName : '',
            "universityEmail": element.universityEmail,
            "universityId":  element.fkUniversityId,
            "universityName": element.universityName
          })
        }
      });
    }
   
    let url = localStorage.getItem('formId') ? 'application/form-update':`application/add-form?`+`role=REPRESENTATIVE`;
    if(localStorage.getItem('formId')) {
      req['formId'] = localStorage.getItem('formId')
    }
    console.log(req)
    this.service.showSpinner()
    this.service.postApi(url,req,1).subscribe((res:any) => {
      this.service.hideSpinner()
      $('#exampleModalCenter').modal('show')
      localStorage.removeItem('formDetails');
      localStorage.removeItem('selectedUniversities');
      localStorage.removeItem('formId');
    })
  }

  openCourse(courseId) {
    let url = window.location.href
    if(url.includes('stag')){
      window.open(`https://webstag.univ.global/about-courses/${courseId}`);
    }else {
      window.open(`https://univ.global/about-courses/${courseId}`);
    }

  }

}
