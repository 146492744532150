import { Component, OnInit } from '@angular/core';
import { apiUrl } from 'src/environments/environment.prod';
import { ServicesService } from 'src/app/services.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css']
})
export class SearchResultsComponent implements OnInit {
  searchCourseList: any=[];
  selectedSubject: any='';
  filteredOptions: any;
  selectedCountry: any;
  selectedQualification: any;
  validity: boolean=false;

  constructor(private service:ServicesService, private router:Router) {
    if(localStorage.getItem('formDetails')) {
      this.service.formDetails = JSON.parse(localStorage.getItem('formDetails'))
    }
   }

  ngOnInit() {
    this.searchCourse();
  }

  searchCourse() {
   
    this.searchCourseList = [];
    
    let url = apiUrl.course.searchCourse +`?page=0&pageSize=999`
    if(this.service.formDetails.section1.subjectCode) {
      // let obj = this.filteredOptions.find(x=>x.value.trim() == this.selectedSubject.trim())
      url = url + `&search=${this.service.formDetails.section1.subjectCode}`
    }
    if(this.service.formDetails.section1.courseCountry) {
      url = url + `&country=${this.service.formDetails.section1.courseCountry}`
    }
    if(this.service.formDetails.section1.qualificationType) {
      url = url + `&qualification=${this.service.formDetails.section1.qualificationType}`
    }
    this.service.getApi(url,2).subscribe((res:any)=>{
      if(res.body.status == 200) {
        let arr = res.body.data.list;
        arr.map(ele=>{
          ele['selected'] = false
        })
        this.searchCourseList = [...arr]
        if(this.searchCourseList.length == 0) {
          this.service.toastErr('No course found!')
        }
      }else {
        this.service.toastErr(res.message)
      }
    },(err:any)=>{
      console.log(err)
      this.service.toastErr(err.error.error)
    })
  }

  openCourse(courseId) {
    let url = window.location.href
    if(url.includes('stag')){
      window.open(`https://webstag.univ.global/about-courses/${courseId}`);
    }else {
      window.open(`https://univ.global/about-courses/${courseId}`);
    }

  }


  select(curr_index) {
    this.searchCourseList.map((ele,index)=>{
      ele.selected = index == curr_index ? !ele.selected : false
    })
  
    let index = this.searchCourseList.findIndex(x=>x.selected == true)
    this.validity = (index != -1) ? true : false
    console.log( this.validity)
  }

  goToSection() {
    let index = this.searchCourseList.findIndex(x=>x.selected == true)
    if(index != -1) {
      let selectedUniversities = localStorage.getItem('selectedUniversities') ?  JSON.parse(localStorage.getItem('selectedUniversities')) : []
      this.searchCourseList.forEach(ele => {
        if(ele.selected == true) {
          selectedUniversities.push(ele)
        }
      });
      // selectedUniversities = [...new Set(selectedUniversities)];
      localStorage.setItem('selectedUniversities',JSON.stringify(selectedUniversities))
      this.router.navigate(['section2'])
    }else {
      this.service.toastErr('Please choose a course!')
    }
  }

}
